import React from 'react'
import { Link } from 'react-router-dom'
const VisitAshalayam = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li> Visit to Don Bosco Ashalayam  </li>
            </ul>
            <h2>Visit to Don Bosco Ashalayam </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row">
      <div className="col-md-12">
      <h1 className="head1">Visit to Don Bosco Ashalayam: A Lesson in Compassion and Giving.</h1>
      <p>On January 15, 2024, a group of ten students from classes VI-XI visited Don Bosco Ashalayam to donate dry ration to the inmates. The Director of Ashalayam warmly welcomed the students. The children at the orphanage performed and showcased their talents, which was heartwarming to watch. It was truly an eye-opening experience for the students of Loreto. It emphasized the importance of giving, compassion, and making efforts to make a positive impact on the lives of those in need.</p>
      </div>
      </div>
<div className="row align-images g-4">
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-1.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-2.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-3.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-4.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-5.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-6.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<img src="/Images/Ashalayam-7.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>

</div>

</div>
</section>
   </>
  )
}

export default VisitAshalayam