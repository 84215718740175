import React from 'react'
import { Link } from 'react-router-dom'
const HeadGirls = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Head Girls  </li>
            </ul>
            <h2>Head Girls </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
        <div className="col-md-12">
        <table className="table table-striped table-bordered">
<tbody>

<tr >
<td colspan="1">  Kaashvi Malhotra <br/>
                 2024-25
             </td>
<td colspan="1">Angela Elizabeth  Domingo <br/>
                 2023-2024
             </td>

      
           
             <td colspan="1">  Meryl Teresa Anthony <br/>
                 2022-23
             </td>
           

             </tr>



    <tr >

<td colspan="1">Sonia Xalxo   <br/>
                 2021-2022
             </td>

      <td colspan="1">Novelle Jibby John<br/>
                 2020-2021
             </td>
           

             <td colspan="">Ria Jain
                 <br/>
                 2019-20
             </td>

             </tr>


       <tr >
         
      <td colspan="1">Rebecca Jose 
                 <br/>
                 2018-2019
             </td>
           <td colspan="">LYDIA NENGHOITHEM HAOKIP
                 <br/>
                 2017-2018
             </td>
             <td colspan="">Zoya Jaffery
                 <br/>
                  2016-2017
             </td>
           

             </tr>
         <tr > 
           
             <td colspan="">Fiza Jaggi 
                 <br/>
                 2015-2016
             </td>
             <td>Shaoni Kar
                 <br/>
                 2014-2015
             </td>

             <td colspan="">Adwaidha L Santosh
                 <br/>
                2013-2014
             </td>

             
         </tr>
         <tr >    
            
             <td>Ashwathy Babu Paul
                 <br/>
                    2012-2013
             </td>
             <td>Aishwarya Sastry<br/>
                2011-2012
             </td>

             
             <td>Damandeep Dang<br/>
               2010-2011
             </td>
             

         </tr>

         <tr >
           
             <td>Megha Jacob<br/>
                  2009-2010
             </td>
             
             <td>Vasundhara Jalali<br/>
                  2008-2009
             </td>

             
             <td>Shireen Lal<br/>
                2007-2008
             </td>
             


         </tr>
         <tr >
           
             <td>Stuti Punn Lible Babu<br/>
                 2006-2007
             </td>
             
             <td>Vineeta Verghese<br/>
                2005-2006
             </td>
             
             <td>Betsy Thomas<br/>
                   2004-2005
             </td>
             
         </tr>

         <tr >
          
             <td>Suchi Nautriyal<br/>
               2003-2004
             </td>
             <td>Christina Almeida<br/>
                   2002-2003  
             </td>
             
             <td>Vasudha Sarda<br/>
              2001-2002
                    
             </td>
             
         </tr>

         <tr >
           
             <td>Christina Fernandes<br/>
                 2000-2001
             </td>
             <td>Jasmine Kaur, Arshilya Singh<br/>
                  1999-2000
             </td>
             

             <td>Ashima Gogia, Komila Punia<br/>
                1998-1999
             </td>

         </tr>


         <tr >
          
             <td>Teena Sethi, Jasmine Kaur<br/>
                 1997-1998
             </td>
             <td>Tanvi Madan, Neha Anand<br/>
                      1996-1997
             </td>

             
             <td>Leona Christy, Kajal Rana<br/>
           1995-1996
             </td>
             
         </tr>



         <tr >
            
             <td>Mora Ann Oomen
                 <br/>
                  1994-1995
             </td>
             <td>Anshul Grover<br/>
                 1993-1994
             </td>
             

             <td>Shagun Sahgal<br/>
                   1992-1993
             </td>

         </tr>




         <tr >
          
             <td>----<br/>
              1991-1992
             </td>
             <td>Archana Gupta<br/>
                 1990-1991
             </td>

             <td>Anjali Puri<br/>
                  1989-1990
             </td>
             

         </tr>


         <tr >
          
             <td>Harmeet Chadha<br/>
                 1988-1989
             </td>
             <td>Ritika Khanna<br/>
                 1987-1988
             </td>

             <td>Sonali Passi<br/>
                  1986-1987
             </td>

             
         </tr>

         <tr >
           
             <td>Meeney Dhir<br/>
                 1985-1986
             </td>
             
             <td>----<br/>
               1984-1985
             </td>
             
             <td>Radhika Kaul<br/>
                1983-1984 
             </td>
             
         </tr>


         <tr >
             
             <td>Bihu Goyle<br/>
                  1982-1983
             </td>
             
             <td>Bandana Singh<br/>
                1981-1982
             </td>

             
             <td>Anita Gupta<br/>
                 1980-1981
             </td>
             

         </tr>


         <tr >
          

             <td>Vandana Grover<br/>
                  1979-1980
             </td>
             
             <td>Jasmeeta Singh<br/>
                   1978-1979
             </td>
             
             <td>Uma Nair<br/>
              1977-1978
             </td>
             
         </tr>

         <tr >
          
             <td>---<br/>
                 1976-1977
             </td>
             
             <td>Vimla Masilanani<br/>
                   1975-1976
             </td>
             
             <td>Rita Goel<br/>
                1974-1975
             </td>
             
         </tr>


         <tr >
          

             <td>Poornima Bakhshi<br/>
                 1973-1974
             </td>
             <td>----<br/>
                  1972-1973
             </td>
             

             <td>----<br/>
                   1971-1972
             </td>

         </tr>

         <tr >

            
             <td>Rashmi Kalaan<br/>
              1970-1971
             </td>
             
             <td>Vinita Wali<br/>
                 1969-1970
             </td>
             
             <td>----<br/>
                    1968-1969
             </td>
             

         </tr>
         
         <tr >
           


             <td>Anna Verghese<br/>
             1967-1968
             </td>
             
             <td>Anu Kaapur<br/>
                  1966-1967
             </td>

             

         </tr>






     </tbody>
</table>
        
        </div>  
       </div>
   </div>
</section>
   </>
  )
}

export default HeadGirls