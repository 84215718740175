import React from 'react'
import { Link } from 'react-router-dom'
const OfficeStaff = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li>  Office Bearers </li>
                <li> Office Staff  </li>
            </ul>
            <h2>Office Staff </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive innertable">
            <table className="table">
              <tbody>
                <tr>
                  <th> S. No. </th>
                  <th>Name</th>
                  <th>Designation</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td> Ms. Steffi Anna Stephen</td>
                  <td> Secretary</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td> Mr. Sandeep Kumar Keshri </td>
                  <td> Accountant</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td> Ms. Nimisha Tomy</td>
                  <td> Asst. Accountant</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td> Mrs.  Anamika Sharma </td>
                  <td>Lab. Assistant </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td> Mr. Parmod Kumar</td>
                  <td> Technician</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td> Mrs. Urvashi Kindo</td>
                  <td> Librarian</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td> Mrs. Jiji Joseph</td>
                  <td> School Nurse</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td> Mr.  Satyendra Bahadur Kushwaha</td>
                  <td> Ecare Assistant</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
   </>
  )
}

export default OfficeStaff