import React from 'react'
import { Link } from 'react-router-dom'
const ContactUs = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li> 
                <li> Contact Us  </li>
            </ul>
            <h2>Contact Us </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
      <div className="row">

         <div className="col-xxl-12 col-lg-12 condetails">
            <p><strong><i className="bi bi-telephone-fill"></i> </strong><Link to="tel:011-25692299">		011-25692299</Link></p>
            <p><strong><i className="bi bi-envelope-fill"></i>  </strong><Link to="mailto:loretodelhi@gmail.com">	loretodelhi@gmail.com</Link> </p>
            <p><strong><i className="bi bi-geo-alt-fill"></i>  </strong> Loreto Convent School, Delhi Cantonment, New Delhi- 110010</p>
         </div>

          <div className="col-xxl-12 col-lg-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14014.084955362254!2d77.1378724!3d28.5841359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ddd31770e03%3A0x5f3db6646381587e!2sLoreto%20delhi!5e0!3m2!1sen!2sin!4v1680691844550!5m2!1sen!2sin" width="100%" height="450" style={{border:"0;"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         </div>

      </div>
   </div>
</section>
   </>
  )
}

export default ContactUs