import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
 

import { Link } from 'react-router-dom'
const OnlineRegistration = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOnlineRegistration();
        setData(data);
      } catch (error) {
        console.error("Error fetching online registration data:", error);
      }  
    };
    fetchData();
  }, []);
  const emptyArray =[]

  return (
     <>
    {data.length > 0 ? (
            data.map((item, index) => (
              <div className="online-reg" key={index}>
                <li className="group">
                  <Link className="custom-title links-urllink" to="https://loretodelhi.campuscare.cloud/" target="_blank">{item.title}</Link>
                </li>
              </div>
            ))
          ) : (
            emptyArray.map((item, index) => <div key={index}></div>)
          )}
     </>
  )
}

export default OnlineRegistration
