import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { getTopper } from '../Service/Api';

const HomeTopperXII = () => {
    const [classXIIData, setClassXIIData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const topperData = await getTopper();
            const currentYear = new Date().getFullYear();
            const yearString = `${currentYear - 1}-${currentYear}`;
            
            const classXII = topperData.filter((item) => item.class === 'XII' && item.sessionYear === yearString); // Adjust the filtering condition as needed
            setClassXIIData(classXII); 
            
            console.log("Filtered class X data for year", yearString, classXII);
        };
        fetchData();
    }, []);


    const emptyArray = [
        { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
    ];

    const options = {
        margin: 30,
        dots: false,
        nav: false,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        },
    };
    return (
        <>
             <div className="row">
                {classXIIData.length > 0 && (
                    <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
                        {classXIIData.map((item1, index) => (
                            <div className="item" key={index}>
                                <div className="topper-section services-style-current-theme text-center mb-lg-50">
                                    <div className="topper-service">
                                        <div className="thumb">
                                            <img
                                                className="rounded-circle"
                                                src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`}
                                                alt="Loreto Convent School Delhi Cantt"
                                            />
                                        </div>
                                        <div className="details clearfix">
                                            <h4 className="title">{item1?.name}</h4>
                                            <p>{item1?.stream}</p>
                                            <ul className="topper-info-list">
                                                <li>
                                                    <span className="font-weight-800 text-theme-colored3">
                                                        Marks:
                                                    </span>{' '}
                                                    {item1?.division}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                )}
                {classXIIData.length === 0 && (
                    <OwlCarousel className="owl-theme emptyFeild" loop margin={10} nav {...options}>
                        {emptyArray.map((data, index) => (
                            <div className="item" key={index}>
                                <div className="topper-section services-style-current-theme text-center mb-lg-50">
                                    <div className="topper-service">
                                        <div className="thumb">
                                            <img
                                                className="rounded-circle"
                                                src="/Images/TAMANNA.jpg"
                                                alt="Loreto Convent School Delhi Cantt"
                                            />
                                        </div>
                                        <div className="details clearfix">
                                            <h4 className="title">TAMANNA REDDY</h4>
                                            <p>Subject</p>
                                            <ul className="topper-info-list">
                                                <li>
                                                    <span className="font-weight-800 text-theme-colored3">
                                                        Marks:
                                                    </span>{' '}
                                                    97%
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                )}
            </div>
        </>
    );
};

export default HomeTopperXII;
