import React from 'react'
import { Link } from 'react-router-dom'
const MissionStatement = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> Mission Statement  </li>
            </ul>
            <h2>Mission Statement </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-xl-6">
            <div className="innerbody_left">
               <div className="innerbody_left__img-box">
                  <div className="innerbody_left__img">
                     <img src="/Images/mission-1.jpg" alt="Loreto Convent School, Delhi Cantt" className="img-fluid"/>
                  </div>
                  <div className="innerbody_left__img-two">
                     <img src="/Images/mission-2.jpg" alt="Loreto Convent School, Delhi Cantt" className="img-fluid"/>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-xl-6">
            <div className="innerbody__right">
               
               <p>We are called as a Loreto School to promote the Glory of God, lovingly discerning God's will for us in growing freedom, sincerity, and justice. We endeavour to empower every child to develop to her best potential and thus proclaim the Glory of God.</p>
               <p>We make this empowerment the distinguishing feature of all our educational effort and are convinced that it takes place best in a school which is a dynamic entity involved continually in discerning the needs of our times and is flexible enough to re-adjust its structures and activities to respond to them.</p>
               <p>We create and sustain a school atmosphere where the values of love, freedom, sincerity, and justice are experienced and lived out by all; where striving for excellence at the level of one's potential is an essential element. We create a conducive environment and ensure appropriate decisions at the administrative and staff levels so that living out those values while striving for excellence becomes a continuous process engaging staff, students, and parents. This takes precedence over all other motivations.</p>
               <p>We make our school a centre where preferential love of the poor is lived out both in attitudes and structures. In cherishing the most deprived of His people, and enabling them to take their place with dignity among the others, our school becomes a place where the Glory of God is truly manifested.</p>
            </div>
         </div>
      </div>


      <div className="row">
         <div className="col-md-12">
       
            <h1 className="head1">SCHOOL GOALS</h1>
            <ul className="lilist">
               <li>Encourage spoken English</li>
               <li>Prevent bullying in school</li>
               <li>Maintain discipline – punctuality, uniform, attitude, regularity, behaviour.</li>
               <li>Technology aided Learning.</li>
            </ul>
         </div>
      </div>
   </div>
</section>
   </>
  )
}

export default MissionStatement