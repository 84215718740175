import { useEffect, useState } from "react";
import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getReading } from "../Service/Api";

const ReadingBooks = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedClass, setSelectedClass] = useState('All');
    useEffect(() => {
        const fetchData = async () => {
            const readingData = await getReading();
            console.log("reading data>>>", readingData);

            // Sort the data by class as numbers
            const sortedData = readingData.sort((a, b) => {
                return parseInt(a.class) - parseInt(b.class);
            });

            setData(sortedData);
            setFilteredData(sortedData); // Initially display all sorted data
        };
        fetchData();
    }, []);

    const handleClassChange = (e) => {
        const selected = e.target.value;
        setSelectedClass(selected);

        if (selected === 'All') {
            setFilteredData(data);
        } else {
            const filtered = data.filter(book => book.class === selected);
            setFilteredData(filtered);
        }
    };

    const handleModal = (id) => {
        navigate(`/SubGallery?id=${id}`);
    };

    // Get unique classes and sort them
    const uniqueClasses = [...new Set(data.map(book => book.class))].sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li> Academics </li>
                            <li> Suggested Reading Books  </li>
                        </ul>
                        <h2>Suggested Reading Books </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="book-search">
                                <img src="/Images/reading.png" alt="Loreto Convent School, Delhi Cantt" />
                                <div className="searchBox">
                                    <label>Class</label>
                                    <div className="month-selection">
                                        <select
                                            className="yearSelection"
                                            value={selectedClass}
                                            onChange={handleClassChange}
                                        >
                                            <option value="All">All</option>
                                            {uniqueClasses.map((className, index) => (
                                                <option key={index} value={className}>{className}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="searchBox">
                                    <input
                                        type="text"
                                        id="myInput"
                                        name="name"
                                        placeholder="Search Here.."
                                    />
                                </div>
                                <div className="clr"></div>
                            </div>
                            <div className="clr"></div>

                            <div className="table-responsive innertable lefttr">
                                <table className="table">
                                    <thead>
                                        <tr><th width="100px">Sl. No</th><th width="50%">Title of Book</th> <th width="30%">Author</th> <th>Class</th></tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((item, index) => (
                                                <tr className="details" key={index} data-month="1">
                                                    <td width="10%">{index + 1}</td>
                                                    <td width="50%">{item.title}</td>
                                                    <td width="30%">{item.author}</td>
                                                    <td width="20%">{item.class}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className="details" data-month="1"><td width="10%">1</td><td width="50%">Title</td><td width="30%">Author</td><td width="20%">Class</td></tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ReadingBooks;
