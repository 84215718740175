import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import HTMLFlipBook from 'react-pageflip';

const Magazine2024 = () => {
  const [active, setActive] = useState(true);
  const [target, setTarget] = useState(null);

  const handleShowFlip = (e) => {
    const getVal = e.currentTarget.getAttribute("data-trigger");
    setTarget(getVal);
    setActive(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setActive(false);
    }, 200);
  }, []);

  const handleClose = () => {
    setActive(false);
  };

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className="control-btn">
        <div onClick={zoomIn}>+</div>
        <div onClick={zoomOut}>-</div>
        <div onClick={resetTransform}>Reset</div>
      </div>
    );
  };
  
  return (
    <>
      <div className={`flip-popup active`} style={{ display: active ? 'block' : 'none' }}>
        <div className="closeme" onClick={handleClose}>+</div>

        <div className="flipOpen" style={{ display: active ? 'block' : 'none' }}>
           
              <HTMLFlipBook
                width={500}
                height={680}
                minWidth={315}
                maxWidth={1000}
                minHeight={400}
                maxHeight={1200}
                mobileScrollSupport={true}
                showCover={true}
                useMouseEvents={true}
                className="flipPageComponent"
                onChange={(e) => setTarget(e)}
                pageBackground="rgb(255, 255, 255)"
                uncutPages="true"
              >
                <div><img src="/Images/magazine/edt241.jpg" /></div>
                <div><img src="/Images/magazine/edt242.jpg" /></div>
                <div><img src="/Images/magazine/edt243.jpg" /></div>
                <div><img src="/Images/magazine/edt244.jpg" /></div>
                <div><img src="/Images/magazine/edt245.jpg" /></div>
                <div><img src="/Images/magazine/edt246.jpg" /></div>
                <div><img src="/Images/magazine/edt247.jpg" /></div>
                <div><img src="/Images/magazine/edt248.jpg" /></div>
                <div><img src="/Images/magazine/edt249.jpg" /></div>
                <div><img src="/Images/magazine/edt2410.jpg" /></div>
                <div><img src="/Images/magazine/edt2411.jpg" /></div>
                <div><img src="/Images/magazine/edt2412.jpg" /></div>
                <div><img src="/Images/magazine/edt2413.jpg" /></div>
                <div><img src="/Images/magazine/edt2414.jpg" /></div>
                <div><img src="/Images/magazine/edt2415.jpg" /></div>
                <div><img src="/Images/magazine/edt2416.jpg" /></div>
                <div><img src="/Images/magazine/edt2417.jpg" /></div>
                <div><img src="/Images/magazine/edt2419.jpg" /></div>
                <div><img src="/Images/magazine/edt2420.jpg" /></div>
                <div><img src="/Images/magazine/edt2421.jpg" /></div>
                <div><img src="/Images/magazine/edt2422.jpg" /></div>
                <div><img src="/Images/magazine/edt2423.jpg" /></div>
                <div><img src="/Images/magazine/edt2424.jpg" /></div>
                <div><img src="/Images/magazine/edt2425.jpg" /></div>
                <div><img src="/Images/magazine/edt2426.jpg" /></div>
                <div><img src="/Images/magazine/edt2427.jpg" /></div>
                <div><img src="/Images/magazine/edt2428.jpg" /></div>
                <div><img src="/Images/magazine/edt2429.jpg" /></div>
                <div><img src="/Images/magazine/edt2430.jpg" /></div>
                <div><img src="/Images/magazine/edt2431.jpg" /></div>
                <div><img src="/Images/magazine/edt2432.jpg" /></div>
                <div><img src="/Images/magazine/edt2434.jpg" /></div>
                <div><img src="/Images/magazine/edt2435.jpg" /></div>
                <div><img src="/Images/magazine/edt2436.jpg" /></div>
                <div><img src="/Images/magazine/edt2437.jpg" /></div>
                <div><img src="/Images/magazine/edt2438.jpg" /></div>
                <div><img src="/Images/magazine/edt2439.jpg" /></div>
                <div><img src="/Images/magazine/edt2440.jpg" /></div>
                <div><img src="/Images/magazine/edt2441.jpg" /></div>
                <div><img src="/Images/magazine/edt2442.jpg" /></div>
                <div><img src="/Images/magazine/edt2443.jpg" /></div>
                <div><img src="/Images/magazine/edt2444.jpg" /></div>
                <div><img src="/Images/magazine/edt2445.jpg" /></div>
                <div><img src="/Images/magazine/edt2446.jpg" /></div>
                <div><img src="/Images/magazine/edt2447.jpg" /></div>
                <div><img src="/Images/magazine/edt2448.jpg" /></div>
                <div><img src="/Images/magazine/edt2449.jpg" /></div>
                <div><img src="/Images/magazine/edt2450.jpg" /></div>
                <div><img src="/Images/magazine/edt2451.jpg" /></div>
                <div><img src="/Images/magazine/edt2452.jpg" /></div>
                <div><img src="/Images/magazine/edt2453.jpg" /></div>
                <div><img src="/Images/magazine/edt2454.jpg" /></div>
                <div><img src="/Images/magazine/edt2455.jpg" /></div>
                <div><img src="/Images/magazine/edt2456.jpg" /></div>
                <div><img src="/Images/magazine/edt2457.jpg" /></div>
                <div><img src="/Images/magazine/edt2458.jpg" /></div>
                <div><img src="/Images/magazine/edt2459.jpg" /></div>
                <div><img src="/Images/magazine/edt2460.jpg" /></div>
                <div><img src="/Images/magazine/edt2461.jpg" /></div>
                <div><img src="/Images/magazine/edt2462.jpg" /></div>
                <div><img src="/Images/magazine/edt2463.jpg" /></div>
                <div><img src="/Images/magazine/edt2464.jpg" /></div>
                <div><img src="/Images/magazine/edt2465.jpg" /></div>
                <div><img src="/Images/magazine/edt2466.jpg" /></div>
                <div><img src="/Images/magazine/edt2467.jpg" /></div>
                <div><img src="/Images/magazine/edt2468.jpg" /></div>
                <div><img src="/Images/magazine/edt2469.jpg" /></div>
                <div><img src="/Images/magazine/edt2470.jpg" /></div>
                <div><img src="/Images/magazine/edt2471.jpg" /></div>
                <div><img src="/Images/magazine/edt2472.jpg" /></div>
                <div><img src="/Images/magazine/edt2473.jpg" /></div>
                <div><img src="/Images/magazine/edt2474.jpg" /></div>
                <div><img src="/Images/magazine/edt2475.jpg" /></div>
                <div><img src="/Images/magazine/edt2476.jpg" /></div>
                <div><img src="/Images/magazine/edt2477.jpg" /></div>
                <div><img src="/Images/magazine/edt2478.jpg" /></div>
                <div><img src="/Images/magazine/edt2479.jpg" /></div>
                <div><img src="/Images/magazine/edt2480.jpg" /></div>
                <div><img src="/Images/magazine/edt2481.jpg" /></div>
                <div><img src="/Images/magazine/edt2482.jpg" /></div>
                <div><img src="/Images/magazine/edt2483.jpg" /></div>
                <div><img src="/Images/magazine/edt2484.jpg" /></div>
                <div><img src="/Images/magazine/edt2486.jpg" /></div>
                <div><img src="/Images/magazine/edt2487.jpg" /></div>
                <div><img src="/Images/magazine/edt2488.jpg" /></div>
                <div><img src="/Images/magazine/edt2489.jpg" /></div>
                <div><img src="/Images/magazine/edt2490.jpg" /></div>
                <div><img src="/Images/magazine/edt2491.jpg" /></div>
                <div><img src="/Images/magazine/edt2492.jpg" /></div>
                <div><img src="/Images/magazine/edt2493.jpg" /></div>
                <div><img src="/Images/magazine/edt2494.jpg" /></div>
                <div><img src="/Images/magazine/edt2495.jpg" /></div>
                <div><img src="/Images/magazine/edt2496.jpg" /></div>
                <div><img src="/Images/magazine/edt2497.jpg" /></div>
                <div><img src="/Images/magazine/edt2498.jpg" /></div>
                <div><img src="/Images/magazine/edt2499.jpg" /></div>
                <div><img src="/Images/magazine/edt24100.jpg" /></div>
                <div><img src="/Images/magazine/edt24101.jpg" /></div>
                <div><img src="/Images/magazine/edt24102.jpg" /></div>
                <div><img src="/Images/magazine/edt24103.jpg" /></div>
                <div><img src="/Images/magazine/edt24104.jpg" /></div>
                <div><img src="/Images/magazine/edt24105.jpg" /></div>
                <div><img src="/Images/magazine/edt24106.jpg" /></div>
                <div><img src="/Images/magazine/edt24107.jpg" /></div>
                <div><img src="/Images/magazine/edt24108.jpg" /></div>
                <div><img src="/Images/magazine/edt24109.jpg" /></div>
                <div><img src="/Images/magazine/edt24110.jpg" /></div>
                <div><img src="/Images/magazine/edt24111.jpg" /></div>
                <div><img src="/Images/magazine/edt24112.jpg" /></div>
                <div><img src="/Images/magazine/edt24113.jpg" /></div>
                <div><img src="/Images/magazine/edt24114.jpg" /></div>
                <div><img src="/Images/magazine/edt24115.jpg" /></div>
                <div><img src="/Images/magazine/edt24116.jpg" /></div>
                <div><img src="/Images/magazine/edt24117.jpg" /></div>
                <div><img src="/Images/magazine/edt24118.jpg" /></div>
                <div><img src="/Images/magazine/edt24119.jpg" /></div>
                <div><img src="/Images/magazine/edt24120.jpg" /></div>
                <div><img src="/Images/magazine/edt24121.jpg" /></div>
                <div><img src="/Images/magazine/edt24122.jpg" /></div>
                <div><img src="/Images/magazine/edt24123.jpg" /></div>
                <div><img src="/Images/magazine/edt24124.jpg" /></div>
                <div><img src="/Images/magazine/edt24125.jpg" /></div>
                <div><img src="/Images/magazine/edt24126.jpg" /></div>
                <div><img src="/Images/magazine/edt24127.jpg" /></div>
                <div><img src="/Images/magazine/edt24128.jpg" /></div>
                <div><img src="/Images/magazine/edt24129.jpg" /></div>
                <div><img src="/Images/magazine/edt24130.jpg" /></div>
                <div><img src="/Images/magazine/edt24131.jpg" /></div>
                <div><img src="/Images/magazine/edt24132.jpg" /></div>
                <div><img src="/Images/magazine/edt24133.jpg" /></div>
                <div><img src="/Images/magazine/edt24134.jpg" /></div>
                <div><img src="/Images/magazine/edt24135.jpg" /></div>
                <div><img src="/Images/magazine/edt24136.jpg" /></div>
                <div><img src="/Images/magazine/edt24137.jpg" /></div>
                <div><img src="/Images/magazine/edt24138.jpg" /></div>
                <div><img src="/Images/magazine/edt24139.jpg" /></div>
                <div><img src="/Images/magazine/edt24140.jpg" /></div>
                <div><img src="/Images/magazine/edt24141.jpg" /></div>
                <div><img src="/Images/magazine/edt24142.jpg" /></div>
                <div><img src="/Images/magazine/edt24143.jpg" /></div>
                <div><img src="/Images/magazine/edt24144.jpg" /></div>
                <div><img src="/Images/magazine/edt24145.jpg" /></div>
                <div><img src="/Images/magazine/edt24146.jpg" /></div>
                <div><img src="/Images/magazine/edt24147.jpg" /></div>
                <div><img src="/Images/magazine/edt24148.jpg" /></div>
                <div><img src="/Images/magazine/edt24149.jpg" /></div>

              </HTMLFlipBook>
           
        </div>

      </div>
      <div className="d-flex flipSelection">
        <div className="flip-block tab-pane active" id="tab1">
          <div className="showflip" data-trigger="1" onClick={handleShowFlip}>
            <img src="/Images/magazine/edt241.jpg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Magazine2024;