import React from 'react'
import { Link } from 'react-router-dom'
const AryabhataGanitChallenge = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Achievements </li>
                <li> Aryabhata Ganit Challenge  </li>
            </ul>
            <h2>Aryabhata Ganit Challenge </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
        <div className="col-md-12">
        <h1 className="head1">ARYABHATA GANIT CHALLENGE (2022-23)</h1>
        
        <p>Mathematics department of Loreto Convent ,Delhi Cantt organized the CBSE Aryabhata Ganit Challenge in our school in two stages. The test mainly focused on applications of 
        Mathematics in day today life.</p>
        <h3 className="head3">FIRST STAGE</h3>
        <p>The pen and paper test was conducted on 5 December 2022 .The question paper was provided by CBSE. It was an objective type paper with no negative marking. All the students from classes eight to ten participated in the test. The award list was uploaded through CBSE link and top three students were identified.</p>
        <div className="table-responsive">
                  <table className="table table-striped table-bordered">
      <tbody>
        <tr>
            <td> DATE </td>
            <td> CLASSES</td>
            <td> NO. OF STUDENTS</td>
            <td> DURATION</td>
            <td>M.MARKS   </td>
        </tr>
        <tr>
            <td>5 December 2022</td>
            <td> 8 TO 10</td>
            <td> 317 </td>
            <td> 1 HOUR </td>
            <td>40</td>
        </tr>
    </tbody>
      </table>
        </div>
        <h3 className="head3">SECOND STAGE</h3>
        <p>The test was conducted online in the school premises.</p>
     <div className="table-responsive">
                  <table className="table table-striped table-bordered align-middle">
     <tbody>
        <tr>
            <td> DATE </td>           
            <td> NO. OF STUDENTS</td>
            <td> DURATION</td>
            <td>M.MARKS   </td>
        </tr>
        <tr>
                 <td>15 December 2022</td>           
                 <td> <div className="yyy"><img src="/Images/MEGHANA---CLASS-VIII-A.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>MEGHANA  (VIII A)</div>
                 <div className="yyy"> <img src="/Images/DISHITA-SAHU---CLASS-X-B.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>DISHITA SAHU  (X B)</div>
                <div className="yyy"> <img src="/Images/JAHNVI-NAGPAL-CLASS-10-B.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>JAHNVI NAGPAL (X B)</div>
                </td>
            <td> 1 HOUR </td>
            <td>60 </td>
        </tr>
    </tbody>
</table>
     </div>
<h1 className="head1">ARYABHATA GANIT CHALLENGE (2019)</h1>

        <p>Mathematics department organized Aryabhata Ganit Challenge, prescribed by CBSE ,in our school in two stages. The test mainly focused on the extent to which children are able to apply mathematics to the daily life.

</p>
        <h3 className="head3">FIRST STAGE</h3>
        <p>The pen and paper test was conducted on 21 November, 2019.The question paper was provided by CBSE. It was objective type paper with no negative marking. All the students from classes eight to ten participated in the test. The award list was uploaded through CBSE link and top three students were identified.

</p>
      
       <div className="table-responsive">
                  <table className="table table-striped table-bordered align-middle">  <tbody>
        <tr>
            <td> DATE </td>
            <td> CLASSES</td>
            <td> NO. OF STUDENTS</td>
            <td> DURATION</td>
            <td>M.MARKS   </td>
        </tr>
        <tr>
            <td>  21 NOV.2019</td>
            <td> 8 TO 10 </td>
            <td> 373 </td>
            <td> 1 HOUR </td>
            <td>60 </td>
        </tr>
    </tbody>
    </table>
    </div>
        <h3 className="head3">SECOND STAGE</h3>
        <p>The test was conducted online in the school premises.

</p>
  <div className="table-responsive">
             <table className="table table-striped table-bordered align-middle">

    <tbody>
        <tr>
            <td> DATE </td>           
            <td> NO. OF STUDENTS</td>
            <td> DURATION</td>
            <td>M.MARKS   </td>
        </tr>
        <tr>
            <td>  25 NOV.2019</td>           
                 <td> <div className="yyy"><img src="/Images/TIA-GUPTA-X-B.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>TIA GUPTA (X-B)</div>
                 <div className="yyy"> <img src="/Images/NAZIA-HASSAN-X--B.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>NAZIA HASSAN (X-B)</div>
                <div className="yyy"> <img src="/Images/PRAPTIBHARDWAJ_X_A.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/><br/>PRAPTI BHARDWAJ (X-A)</div>             
                </td>
            <td> 1 HOUR </td>
            <td>60 </td>
        </tr>
    </tbody>
</table>
  </div>

        </div>  
       </div>
   </div>
</section>
   </>
  )
}

export default AryabhataGanitChallenge