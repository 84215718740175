import { useEffect, useState } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import { getFaculty } from "../Service/Api";
const TeachingStaff = () => { 
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const readingData = await getFaculty();
                console.log("Faculty Lsist",readingData)
                // Check if readingData is an array
                if (Array.isArray(readingData)) {
                    // Sort the data by class as numbers
                    const sortedData = readingData.sort((a, b) => {
                        return parseInt(a.order) - parseInt(b.order);
                    });

                    setData(sortedData);
                    setFilteredData(sortedData); // Initially display all sorted data
                } else {
                    console.error('Error: readingData is not an array');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Teaching Staff   </li>
            </ul>
            <h2>Teaching Staff  </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
           <div className="col-lg-12">
             <div className="table-responsive innertable">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th> S. No. </th>
                                <th>Name</th>
                                <th>Designation</th>
                                {/* <th>Qualification </th>  */}
                            </tr>
                            {filteredData.length > 0 ? (
                                            filteredData.map((item, index) => (
                             <tr>
                                <td>{item.order}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                                {/* <td>{item.qualification} </td> */}
                            </tr> 
                            ))
                            ) : (
                                <tr>
                                <td>1</td>
                                <td>Name</td>
                                <td>Designation</td>
                                {/* <td>Qualification </td> */}
                            </tr> 
                            )}
                        </tbody>
                    </table>
                </div>
           </div>
       </div>
   </div>
</section>
   </>
  )
}

export default TeachingStaff