import React from 'react'
import { Link } from 'react-router-dom'
const OurFounder = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Our Founder  </li>
            </ul>
            <h2>Our Founder </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<h1 className="head1">A WOMAN BEYOND COMPARE</h1>
<p><img src="/Images/mary-ward.jpg"  className="img-fluid left-align-images" alt="Loreto Convent School, Delhi Cantt"/>Foundress of the Institute of the Blessed Virgin Mary (Loreto), pioneer in the field of education, Mary Ward foresaw the need for a sound religious and moral education for young women who would assume responsibility in society.</p>
<p>The serenity and confidence in God with which Mary Ward accepted opposition and sufferings makes her a model of faith, trust and courage for all. Her conviction was that “Women in time to come will do much.”</p>
<p>In 1985 we celebrated the 4th Centenary of the birth of MARY WARD. In 2011, we celebrated the 4th Centenary of the founding of the Institute of the Blessed Virgin Mary (the Congregation of Loreto). Although her aims and objectives of education were formulated in the early 17th century, so farseeing was she, that the goals of our education today are in essence the same as the goals of our Foundress. In modern society, the most important issues to be raised are issues of values and the translation of these values into action. Hence, the goal of our education today continues to be the all-round development of the child. This prepares every student to take her place and make her contribution to society.</p>
<p>Thus Loreto education today follows the goals set by Mary Ward – ‘that incomparable woman” who saw Integrity, Justice, Freedom and Love as essential qualities for any person.</p>
</div>

<div className="col-md-12  mt-4">
<h1 className="head1">MOTHER FRANCES Teresa BALL</h1>
<p><img src="/Images/founder_2.jpg"  className="img-fluid right-align-images" alt="Loreto Convent School, Delhi Cantt"/>Frances Teresa Ball, commonly known as Teresa Ball, was a remarkable and courageous woman born on January 6th, 1794, in Dublin, Ireland. She was the youngest child of John Ball and Mabel Clare Bennett. Frances, or Fanny as she was called, displayed a warm and affectionate nature with a lively disposition during her childhood. Unfortunately, her father passed away in 1804, just a year after she left for school in York, England, at the age of nine.</p>

    <p>Frances received a significant calling during a ball in 1810 when she heard the words, "Seek first the Kingdom of God and His justice, and all these things shall be added unto you." Despite her initial reluctance, she eventually entered religious life, taking the name Teresa and choosing St. Teresa of Avila as her patron saint.</p>
    <p>Under the guidance of Archbishop Daniel Murray, Teresa was trained in the Bar Convent, York. She faced challenges, including the deaths of her first two companions due to consumption. However, Teresa's strong personality and deep faith allowed her to persevere.</p>
    <p>In 1821, Archbishop Murray believed the time was right for Teresa to establish the Institute of the Blessed Virgin Mary in Ireland. She faced anxiety and uncertainty but was supported by Archbishop Murray and found strength in her motto, 'The mercies of the Lord I will sing forever.'</p>
    <p>Teresa and her companions arrived in Dublin on August 12th, 1821, and after temporary arrangements, they moved to Rathfarnham House on November 4th, 1822. Despite initial challenges, the Loreto community grew, and by 1825, there were nine members. Teresa's dedication and missionary zeal led to the establishment of Loreto communities and schools across Ireland.</p>
    <p>In 1841, the scope of Teresa's mission expanded globally when Bishop Carew of Calcutta requested Loreto Sisters for Catholic schools in India. This marked the beginning of Loreto's overseas missions, with subsequent foundations in Mauritius, Canada, England, and Spain.</p>
    <p>Teresa was described as modest, gentle, dignified, elegant, and refined. She had a deep devotion to the Blessed Sacrament and Our Blessed Lady. Her health began to decline in 1855, but she remained cheerful and interested in the welfare of the Institute until her passing on May 19th, 1861.</p>
    <p>Teresa's vision for education included a holistic approach with an emphasis on languages, arts, sciences, and care for the less fortunate. She faced personal and institutional challenges but left a lasting legacy. The Loreto tradition of compassion and commitment to education continues to thrive, and Teresa's spirit lives on in Loreto communities worldwide.</p>
    <p>This biography aims to introduce children to the inspiring life of Teresa Ball, emphasizing her care for the poor and her dedication to education. The hope is that children will learn about Teresa's values and be inspired to make a positive impact in their own lives and communities.</p>
</div>

<div className="col-md-12  mt-4">
<h1 className="head1">MOTHER DELPHINE HART</h1>
<p><img src="/Images/founder_3.jpg"  className="img-fluid left-align-images" alt="Loreto Convent School, Delhi Cantt"/>
Mary Hart, later known as Mother Delphine Hart, led a remarkable life dedicated to religious service and education. Born in Dublin on December 7th, 1817, she was baptized in St. Catherine’s Church, Meath Street, and was the eldest child of Thomas Hart and Isabella Callaghan. Mary took on the religious name Delphine Hart when she became a Loreto nun.</p>
<p>In 1841, Mary, now Mother Delphine, along with seven sisters and four postulants, embarked on a journey to Calcutta, India, on the ship named The Scotia. This marked the beginning of her missionary work in India.</p>
<p>Mother Delphine was characterized by her strong belief in fidelity to the religious rule, coupled with sound common sense and a compassionate approach to human needs. She prioritized the spirit of the Institute over strict adherence to rules, displaying practicality and adaptability. Her dedication and hard work were acknowledged by both civic authorities and the Church.</p>
<p>She was known for the quality of education provided under her supervision, attracting more pupils than the schools could accommodate. Mother Delphine was described as a bright, warm person with a shrewd, kind nature. She had a keen ability to understand the unspoken thoughts and feelings of young children, passing on this attentiveness to the young sisters under her care.</p>
<p>Mother Delphine was an avid lifelong learner, contributing to the success of the schools she founded. She exhibited a missionary spirit by opening new houses throughout India and, during times of uprising, expressed fearlessness, trusting the protection of the Blessed Mother of God.</p>
<p>Her commitment to Institute unity was evident in her efforts to unify scattered houses, as seen in her visits to various Loreto Convents in Italy and Germany. Mother Delphine had a strong option for the poor, demonstrating concern for orphans, widows, and the suffering of the poor. References in her letters emphasized her care for the orphan children at Entally and Bow Bazar.</p>
<p>Throughout her tenure as Chief Superior, Mother Delphine maintained remarkable cheerfulness and optimism, rooted in her faith and trust in God. Her life's high point came with the reunion of Loreto Convents in India with Rathfarnham in 1880-1881, a goal she had fervently prayed, worked, and struggled for.</p>
<p>Mother Delphine passed away on July 24, 1889, at Loreto Priory, Hazaribag, India, having dedicated 48 years, 6 months, and 24 days to the Loreto Indian mission. In her 72nd year, she left a lasting legacy of faith, obedience, compassion, and a tireless commitment to education and the welfare of the poor.</p>
</div>

<div className="col-md-12  mt-4">
<h1 className="head1">AGNES WALSH</h1>
<p><img src="/Images/Mother-Agnes-Walsh.jpg"  className="img-fluid right-align-images" alt="Loreto Convent School, Delhi Cantt"/>
Mother Agnes Walsh, born on 21st February, dedicated her entire life for the cause of education. Mother Agnes was appointed Provincial of India in1962.Being a visionary, she proposed the formation of LORETO DELHI at a meeting held in Calcutta on 6th January 1964. She did not wait for the building to be constructed. Instead, she acquired a bungalow in the Cantonment,which is now 28, The Mall and started classes in tents. The school shifted to its present location in July 1966.
</p>
</div> 


</div>
</div>
</section>
   </>
  )
}

export default OurFounder