import React, { useEffect, useState } from 'react';
import { getBirthdays } from '../Service/Api';
import OwlCarousel from 'react-owl-carousel';
const HomeBirthday = () => {
    const [data, setData] = useState([]); // State for gallery data


    useEffect(() => {
        const fetchData = async () => {
            const birthdayData = await getBirthdays();
            setData(birthdayData);
        };
        fetchData();
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const birthdayData = await getBirthdays();
    //             setData(birthdayData);
    //             console.log("Data received.", birthdayData)
    //         } catch (error) {
    //             console.error("Error fetching birthday data:", error);
    //             // Handle error (e.g., show error message)
    //         } finally {
    //             setLoading(false); // Set loading to false after data fetching is complete
    //         }
    //     };
    //     fetchData();
    // }, []);


    const options = {
        margin: 30,
        dots: false,
        nav: false,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,

            },
            1200: {
                items: 1,

            }
        },
    };
    return (
        <>
            <div className="row">
                {data.length > 0 && (
                    <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                        {data.length > 0 && data.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="topper-section services-style-current-theme text-center mb-lg-50 Birthdaybg">
                                    <div className="topper-service">
                                        <div className="thumb">
                                            <img className="rounded-circle" src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="Loreto Convent School Delhi Cantt" />
                                        </div>
                                        <div className="details clearfix">
                                            <h4 className="title">{item.name}</h4> {/* Use item.name instead of data.name */}
                                            <ul className="topper-info-list">
                                                <li><span className="font-weight-800 text-theme-colored3">Class:</span>{item.class}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </OwlCarousel>
                     )}
                {data.length === 0 && (
                    <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>

                        <div className="item">
                            <div className="topper-section services-style-current-theme text-center mb-lg-50 Birthdaybg">
                                <div class="topper-service">
                                    <div class="thumb">
                                        <img class="rounded-circle" src="/Images/profilethumb.png" alt="Loreto Convent School Delhi Cantt" />
                                    </div>
                                    <div class="details clearfix">
                                        <h4 class="title">  Student Name</h4>
                                        <ul class="topper-info-list">
                                            <li><span class="font-weight-800 text-theme-colored3">Class:</span> -</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </OwlCarousel>
                )}
            </div>

        </>
    )
}

export default HomeBirthday