import React, {useState, useEffect} from 'react'

import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import { getGalleryInner } from "../Service/Api";
const HomeGallery = () => {
    const [data, setData] = useState([]); // State for gallery data
    const [loading, setLoading] = useState(true); // State for loading indicator
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const topperData1 = await getGalleryInner();
            const topperData = topperData1.filter((item) => item.category === 'PhotoGallery'); 
            console.log(topperData);
            
            // Sort the data by date in descending order and then slice to get the last 6 entries
            const getcurrentData = topperData
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .slice(0, 6);
            
            setData(getcurrentData);
          } catch (error) {
            console.error("Error fetching gallery data:", error);
            // Handle error (e.g., show error message)
          } finally {
            setLoading(false); // Set loading to false after data fetching is complete
          }
        };
        fetchData();
      }, []);
    const emptyArray = [
        // { description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.", attachments: "director.jpg" },   
      ]
  const options = {
    margin: 30,
    dots:false,
    nav: false,
    responsiveClass: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items:1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items:4,

        },
        1200: {
            items:5,

        }
    },
};
  return (
     <> 
     <div className="row">
     {loading ? (
                  <p>Loading gallery...</p>
                ) : (
     <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
         {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
        <div className="item" key={index}>
            <div className="project-item">
                <div className="project-thumb gal-big">
                    <Link to={`/SubGallery?id=${item._id}`}><img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></Link>
                </div>
                <div className="project-content">
                    <h2 className="title">{item.title} </h2>                  
                </div>
            </div>
        </div>
       )):emptyArray.map((data, index) => ( <div key={index} className='item'>
              
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to=" "><img src="/Images/gal-2.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></Link>
                </div>
                <div className="project-content">
                    <h2 className="title">Album </h2>
                    <span>Name</span>
                </div>
            </div>
        </div> 
       ))}
        {/* <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to=" "><img src="/Images/gal-2.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></Link>
                </div>
                <div className="project-content">
                    <h2 className="title">Album </h2>
                    <span>Name</span>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-big">
                    <Link to=" "><img src="/Images/gal-3.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></Link>
                </div>
                <div className="project-content">
                    <h2 className="title">Album </h2>
                    <span>Name</span>
                </div>
            </div>
        </div>
        <div className="item">
            <div className="project-item">
                <div className="project-thumb gal-small">
                    <Link to=" "><img src="/Images/gal-4.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></Link>
                </div>
                <div className="project-content">
                    <h2 className="title">Album </h2>
                    <span>Name</span>
                </div>
            </div>
        </div> */}
    
        </OwlCarousel> 
         )}
     </div>
     </>
  )
}

export default HomeGallery