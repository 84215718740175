import React from 'react'
import { Link } from 'react-router-dom'
const ParentPortal = () => {
  return (
    <>
    <div className="erp_links"> 
        <Link to="https://loretodelhi.campuscare.cloud/" target="_blank"><img src="/Images/Campulogo.png" className="img-fluid"  alt="Loreto Convent School Delhi Cantt"/></Link> 
    </div>
    </>
  )
}

export default ParentPortal
